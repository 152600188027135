/* App CSS */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Index CSS */
body{
  background-color: #BDE6F1;
  font-family: 'Edu VIC WA NT Beginner', cursive;
  color: white;
  text-align: center;
  margin: 0rem;
}
h1{
  margin-bottom: 2rem;
}
hr{
  border: dotted #EAF6F6 3px;
  border-bottom: none;
  width: 4%;
  margin: 25px auto;
}
.landing{
  text-align: center;
}
.programmer{
  margin: 0;
}
.pro{
  text-decoration:underline;
}

.intro{
  margin: 0 auto;
}
.rel-courses{
  padding: 0;
  list-style-type:none;
  line-height: 1.5;
}

.list-title{
  list-style-type:none;
  padding: 0;
}
.projects{
  margin: 0 auto;
}
.icon {
  width: 15%;
}
.hobby-row{
  width: 50%;
  margin: 0 auto;
}
.hobby-row h3{
    text-align: center;
}
.hobby-odd .hobby-text{
text-align: left;
}
.hobby-even .hobby-text{
text-align: right;
}
.hobby-icon{
  width: 20%
}
.hobby-odd img{
  float: left;
  margin-right: 1rem;
}
.hobby-even img{
  float: right;
}
.hobby-row.hobby-even {
  margin-top: 5rem;
}
.footer{
  clear: right;
}

/* Navbar CSS */
.navbar {
  position: sticky;
  background-color: #EEAB44;
  padding: 15px 0;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.nav-logo {
  font-size: 24px;
  font-weight: bold;
  color: white;
  text-decoration: none;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.nav-links li {
  display: inline;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #94d2bd;
}

/* Gallery Page CSS */
.gallery-page {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.album-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-bottom: 40px;
}

.album-item {
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s;
}

.album-item:hover {
  transform: translateY(-5px);
}

.album-cover {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.gallery-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.gallery-close {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  font-size: 30px;
  cursor: pointer;
  z-index: 1001;
}

.gallery-title {
  color: white;
  text-align: center;
  margin-bottom: 20px;
}

.gallery-nav {
  position: absolute;
  top: 50%;
  background: rgba(255, 255, 255, 0.9);
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 24px;
}

.gallery-prev {
  left: 20px;
}

.gallery-next {
  right: 20px;
}

.gallery-image {
  max-width: 90%;
  height: auto;
  margin: 0 auto;
  display: block;
}

.photo-info {
  color: white;
  text-align: center;
  padding: 20px;
}
.album-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.album {
  width: 800px;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s ease-in-out;
}

.album:hover {
  transform: scale(1.05);
}

.album-cover {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
}


/* Flight Log CSS */
.container {
  max-width: 800px;
  margin: 20px auto;
  padding: 30px;
  color: white; 
}

.header {
  text-align: center;
  margin-bottom: 30px;
}


.stats {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
}

.stat-item {
  text-align: center;
  padding: 1rem;
  border-radius: 5px;
  background-color: #EEAB44; 
  color: white; 
}

.flight-log {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.flight-entry {
  background-color: #EEAB44;
  padding: 1.5rem;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: white; 
}

.flight-date {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.aircraft, .route, .instructor, .dual-received, .pic-time, .total-time {
  color: white; 
  margin-bottom: 0.5rem;
}

.flight-entry .dual-received,
.flight-entry .pic-time,
.flight-entry .total-time {
  font-style: italic;
}

.terminal {
  width: 100%;
  max-width: 800px;
  height: 550px;
  background: black;
  color: limegreen;
  font-family: "Courier New", monospace;
  padding: 10px;
  overflow: hidden;
  border: 2px solid limegreen;
  display: flex;
  flex-direction: column;
  margin: 3rem auto;
}

.terminal-header {
  font-weight: bold;
  margin-bottom: 5px;
}

.terminal-body {
  flex-grow: 1;
  overflow-y: auto; /* Enable scrolling */
  padding-right: 10px;
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: limegreen black;
}

.terminal-body::-webkit-scrollbar {
  width: 8px;
}

.terminal-body::-webkit-scrollbar-thumb {
  background: limegreen;
  border-radius: 5px;
}

.terminal-body::-webkit-scrollbar-track {
  background: black;
}

.terminal-input-line {
  display: flex;
  align-items: center;
}

.terminal-symbol {
  margin-right: 5px;
}

.terminal-input {
  background: none;
  border: none;
  color: limegreen;
  font-size: 16px;
  outline: none;
  width: 100%;
}
.terminal-output {
  white-space: pre;
  font-family: monospace;
}